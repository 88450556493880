import { makeAutoObservable, toJS } from "mobx";
import {
  BaristaBS,
  ContragentBS,
  DealBS,
  OperationType,
  OperationTypeList,
  PaymentType,
  IStatement,
  Subconto,
} from "../types";
import {
  fetchBankStatementsBarista,
  fetchBankStatementsContragents,
  fetchDeals,
  fetchOperationType,
  fetchPaymentType,
  fetchUpdateBankStatement,
} from "api";
import { RootStore } from "../../../store/rootStore";
import { showAlertPopup } from "../../../ui/alert";

export class EditStatementStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  guid: string = "";
  initialBankStatement: IStatement | null = null;
  currentBankStatement: any = null;
  dealsList: DealBS[] = [];
  operationTypesList: OperationType[] = [];
  paymentTypesList: PaymentType[] = [];
  operationTypesListObject: OperationTypeList = {
    in: [],
    out: [],
  };
  pickedDealId: number | null = null;
  pickedOperationTypeId: number | null = null;
  pickedPaymentTypeId: number | null = null;
  baristasList: BaristaBS[] = [];
  contragentsList: ContragentBS[] = [];
  pickedSubcontoType: Subconto | null = null;
  isUpdating: boolean = false;

  setGuid = (newGuid: string) => {
    this.guid = newGuid;
  };

  setCurrentBankStatement = (val: IStatement) => {
    this.currentBankStatement = val;
    if (val?.contragent_type === "contragent") {
      this.getDeals();
    }
  };

  setInitialBankStatement = (val: IStatement) => {
    this.initialBankStatement = val;
    this.setPickedSubcontoType(val?.contragent_type);
  };

  setDealList = (val: DealBS[]) => {
    this.dealsList = val;
  };

  setOperationTypesList = (val: OperationType[]) => {
    this.operationTypesList = val;
  };

  setPaymentTypesList = (val: PaymentType[]) => {
    this.paymentTypesList = val;
  };

  setOperationTypesListObject = (val: OperationTypeList) => {
    this.operationTypesListObject = val;
  };

  setPickedDealId = (val: number | null) => {
    this.pickedDealId = val;
  };

  setPickedPaymentTypeId = (val: number | null) => {
    this.pickedPaymentTypeId = val;
  };

  setPickedOperationTypeId = (val: number | null) => {
    this.pickedOperationTypeId = val;
  };

  setBaristasList = (val: BaristaBS[]) => {
    this.baristasList = val;
  };

  setContragentsList = (val: ContragentBS[]) => {
    this.contragentsList = val;
  };

  setPickedSubcontoType = (val: Subconto) => {
    this.pickedSubcontoType = val;
  };

  setIsUpdating = (val: boolean) => {
    this.isUpdating = val;
  };

  getDeals = async () => {
    if (!this.currentBankStatement) return;
    const response = await fetchDeals(
      toJS(this.currentBankStatement).contragent_id
    );
    if (response) {
      if (response.data.message) {
        showAlertPopup("Ошибка", `${response.data.message}`, "error");
      }
      this.setDealList(response?.data.data);
    }
  };

  getOperationType = async () => {
    const response = await fetchOperationType();
    this.setOperationTypesListObject(response?.data.data);
    if (this.currentBankStatement) {
      this.setOperationTypesList(
        response?.data.data[toJS(this.currentBankStatement?.pay_type_id)]
      );
    }
  };

  getPaymentType = async () => {
    const response = await fetchPaymentType();
    this.setPaymentTypesList(response?.data.data);
  };

  getBaristas = async () => {
    const response = await fetchBankStatementsBarista();
    if (response) {
      this.setBaristasList(response.data.data);
    }
  };

  getContragents = async () => {
    const response = await fetchBankStatementsContragents();
    if (response) {
      this.setContragentsList(response.data.data);
    }
  };

  firstSubconto = {
    user: this.baristasList,
    contragent: this.contragentsList,
    none: null,
  };

  secondSubconto = {
    user: null,
    contragent: this.dealsList,
    none: null,
  };

  get isBankStatementNotChanged() {
    return (
      JSON.stringify(this.currentBankStatement) ===
      JSON.stringify(this.initialBankStatement)
    );
  }

  get isPaymentOrOperationTypesChanged() {
    return (
      this.currentBankStatement?.operation_type_id !==
        this.initialBankStatement?.operation_type_id ||
      this.currentBankStatement?.pay_type_id !==
        this.initialBankStatement?.pay_type_id
    );
  }

  updateBankStatement = async () => {
    if (!this.currentBankStatement) return;
    this.setIsUpdating(true);
    const response = await fetchUpdateBankStatement(
      this.currentBankStatement.id,
      this.pickedDealId,
      this.pickedOperationTypeId,
      this.pickedPaymentTypeId,
      this.currentBankStatement.contragent_id,
      null
    );
    if (response?.data.success) {
      showAlertPopup(
        "Выписка обновлена!",
        "Данные успешно изменены",
        "success"
      );
      this.setInitialBankStatement(this.currentBankStatement);
    }
    this.setIsUpdating(false);

    return Promise.resolve();
  };
}
