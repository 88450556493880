import { useStore } from "store";
import { SortIcon } from "../icons";

export const TableHeaderBS = () => {
  const {
    RootStore: {
      BankStatementsStore: { sortParamBS, sortOrderBS, handleColumnTitleClickBS },
    },
  } = useStore();

  return (
    <div className="statements-table__header">
      <div
        className="statements-table__header-element sorted f144"
        onClick={() => handleColumnTitleClickBS("date")}
      >
        <p>Дата проведения</p>
        <SortIcon order={sortOrderBS} isVisible={sortParamBS === "date"} />
      </div>
      <div
        className="statements-table__header-element sorted f144"
        onClick={() => handleColumnTitleClickBS("docId")}
      >
        <span className="divider" />
        <p>№ документа</p>
        <SortIcon order={sortOrderBS} isVisible={sortParamBS === "docId"} />
      </div>
      <div className="statements-table__header-element sorted f284">
        <span className="divider" />
        <p>Плательщик / Получатель</p>
        <SortIcon order={sortOrderBS} isVisible={sortParamBS === "contragent"} />
      </div>
      <div className="statements-table__header-element f144">
        <span className="divider" />
        <p>Сумма ₽</p>
      </div>
      <div className="statements-table__header-element f120">
        <span className="divider" />
        <p>Счет / Касса</p>
      </div>
      <div className="statements-table__header-element f290">
        <span className="divider" />
        <p>Вид операции</p>
      </div>
      <div className="statements-table__header-element f154 ">
        <span className="divider" />
        <p>Статус</p>
      </div>
    </div>
  );
};
