import classNames from "classnames";
import type {ButtonHTMLAttributes} from "react";
import cls from './styles.module.scss';

export type ButtonTheme = 'primary' | 'outlined' | 'empty';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: ButtonTheme;
    isLoading?: boolean;
    className?: string;
    onClick: (e?: any) => void;
}

export const Button = (props: Props) => {
    const {
        disabled = false,
        isLoading = false,
        autoFocus = false,
        type = 'button',
        theme = 'primary',
        children,
        className,
        onClick,
        ...otherProps
    } = props;

    return (
        <button
            disabled={disabled}
            type={type}
            className={classNames(cls.button, cls[theme], className)}
            onClick={onClick}
            {...otherProps}
        >
            {children}
        </button>
    )
}