import { Nullable } from "assets/types";

export type PermissionTypeKey =
  | "Invoice"
  | "Cash_Order"
  | "Manual_Input"
  | "Advance_Report"
  | "Inventory"
  | "Scheduling"
  | "Bank_Statements";
export type PermissionTypeValue =
  | "Накладная"
  | "Кассовый ордер"
  | "Ручной ввод"
  | "Авансовый отчет"
  | "Инвентаризация"
  | "Планирование графика"
  | "Банковские выписки";
export type RoleUserData = Pick<
  IProfileData,
  "spot_id" | "id" | "email" | "name" | "surname"
>;
export type SendPermissions = {
  permissions: PermissionToSend;
  user_id: Nullable<number>;
};
type PermissionToSend = Record<string, number>;

export interface IEmployeeData {
  id: number;
  avatar: Nullable<string>;
  email: string;
  job_title: string;
  name: string;
  phone: Nullable<string>;
  spot_id: number;
  surname: string;
  third_name: string;
  allow_edit_permissions: boolean;
  employee_type: number | null;
}

export interface IAvailableSpot {
  id: number;
  spotName: string;
  spotAddress: string;
}

export interface Permission {
  slug: string;
  description: PermissionTypeValue;
  active: boolean;
}

export type PermissionCategory = {
  [key: string]: Permission;
};

export interface IPermissions {
  [key: string]: PermissionCategory;
}

export interface IMatrixRow {
  rows: IMatrixSubRow[];
  label: PermissionTypeKey;
  subLabel?: PermissionTypeValue;
  active: boolean;
}

export interface IMatrixSubRow {
  label: PermissionTypeValue;
  value: boolean;
  active: boolean;
  slug: string;
  description: PermissionTypeValue;
}

export interface IProfileData {
  id: number;
  name: string;
  surname: string;
  third_name: Nullable<string>;
  email: string;
  email_verified_at: Nullable<string>;
  fix_pay: number | null;
  phone: Nullable<string>;
  timezone: 3;
  telegram: Nullable<string>;
  date_of_born: Nullable<string>;
  avatar: Nullable<string>;
  slogan: Nullable<string>;
  token: Nullable<string>;
  spot_id: number;
  job_title: string;
  job_title_en: string;
  job_title_id: number;
  inn: string;
  snils: string;
  payment_place: number;
  guid_1c: string;
  individual_guid: string;
  created_at: string;
  updated_at: Nullable<string>;
  deleted_at: Nullable<string>;
  login_at: string;
  spot: string;
  dkk?: number | null;
}

export interface IEmploymentPeriod {
  //YYYY-MM-DD
  value: string;
  text: string;
}

export enum PaymentTypes {
  bonus = 3,
  contract,
}
