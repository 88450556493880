import { Progress } from "antd";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useStore } from "store";
import { Modal } from "widgets/modal";
import cls from "./upload-results-modal-styles.module.scss";
import { ModalButtons } from "widgets/modal/buttons";

interface UploadResultsModalProps {
  onClose: () => void;
}

const UploadResultsModalComponent: FC<UploadResultsModalProps> = ({ onClose }) => {
  const {
    RootStore: {
      BankStatementsStore: { isUploading, uploadedInfo, setUploadedInfo },
    },
  } = useStore();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: number;

    const delay = Math.random() * (200 - 100) + 100;

    if (isUploading && progress < 100) {
      interval = window.setInterval(() => {
        setProgress(prev => (prev < 90 ? prev + 1 : prev));
      }, delay);
    }

    if (!isUploading) setProgress(100);

    return () => {
      window.clearInterval(interval);
    };
  }, [isUploading, progress]);

  return (
    <Modal
      title={isUploading ? "Загрузка" : "Выписка загружена"}
      maxWidth="480px"
      minWidth="480px"
      onClose={() => {}}
    >
      <div className={cls["wrapper"]}>
        <Progress
          showInfo={false}
          percent={progress}
          trailColor="#F7F7F7"
          size={"small"}
          strokeColor={"#CAF711"}
        />
        <div>
          <div className={cls["status"]}>
            <p>Успешно</p>
            <p>{uploadedInfo?.success ?? 0}</p>
          </div>
          <div className={cls["status"]}>
            <p style={{ color: "#EB5757" }}>С ошибками</p>
            <p style={{ color: "#EB5757" }}>{uploadedInfo?.error ?? 0}</p>
          </div>
        </div>
        <div style={{ alignSelf: "flex-end" }}>
          <ModalButtons
            onPrimaryButtonClick={() => {
              onClose();
              setUploadedInfo(null);
            }}
            primaryButtonLabel={"Подтвердить"}
            primaryButtonDisabled={isUploading}
          />
        </div>
      </div>
    </Modal>
  );
};

export const UploadResultsModal = observer(UploadResultsModalComponent);
